<template>
    <b-container fluid>
      <b-row>
        <b-col md="12">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ cvCardTitle }}</h4>
            </template>
            <template v-slot:body>
              <b-row class="mb-3">
                <b-col class="w-100 col-8 col-sm-6 col-md-6 col-lg-5 mb-2">
                  <!-- <b-form-input v-model="filter" type="search" :placeholder="cvSearchText"></b-form-input> -->
                  <!-- Backend Search -->
                  <input class="form-control"
                    id="search"
                    v-model="whereFilter.search_param"
                    type="search"
                    @input="searchUserWithParams"
                    :placeholder="cvSearchText"
                  /><!-- Backend Search -->
                </b-col>

                <b-col class="col-4 col-sm-2 col-md-2 col-lg-1 mb-2">
                  <!-- Backend csv download -->
                  <b-btn variant="primary" class="Download_button" @click="backendCsvDownload"> CSV</b-btn>
                  <!-- Backend csv download -->
                </b-col>

                <b-col sm="4" md="4" lg="3" xl="3" class="mb-2" v-if="userData.user_role == 'USERROLE11111'" :key="(updatedKey+1)">
                  <select v-model="whereFilter.board" class="form-control" @change="setFilters" title="Board name">
                    <option :value="null" disabled>Choose Board</option>
                    <option :value=null>All</option>
                    <option v-for="(student, index) of studentfilter['board']" :key="('board'+index+1)" :value="student.board">
                      {{student.board}}
                    </option>
                  </select>
                </b-col>

                <b-col sm="4" md="4" lg="3" xl="3" class="mb-2" v-if="userData.user_role == 'USERROLE11111'" :key="(updatedKey+2)">
                  <select v-model="whereFilter.grade" class="form-control" @change="setFilters" title="Grade/Class">
                    <option :value="null" disabled>Choose Grade </option>
                    <option :value=null>All</option>
                    <option v-for="(student, index) of studentfilter['grade']" :key="('grade'+index+1)" :value="student.grade">
                      {{student.grade}}
                    </option>
                  </select>
                </b-col>

                <b-col sm="4" md="4" lg="3" xl="3" class="mb-2" v-if="userData.user_role == 'USERROLE11111'" :key="(updatedKey+3)">
                  <select v-model="whereFilter.product" class="form-control" @change="setFilters" title="Product name">
                    <option :value="null" disabled>Choose Product </option>
                    <option :value=null>All</option>
                    <option v-for="(student, index) of studentfilter['product']" :key="('product'+index+1)" :value="student.product">
                      {{student.product}}
                    </option>
                  </select>
                </b-col>

                <b-col class="w-100 col-12 col-sm-4 col-md-4 col-lg-9 mb-2">
                  <div class="dateRange">
                    <date-range-picker ref="picker" :show-dropdowns="true" class="w-100" :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy' }"  v-model="whereFilter.dateRange"  @update="userListDateWise()" ></date-range-picker>
                  </div>
                </b-col>

              </b-row>
              <b-row>
                <b-col md="12" v-if="userRegLeadObjList && userRegLeadObjList.length > 0">
                  <b-table hover responsive
                    :items="userRegLeadObjList"
                    :fields="columns"
                    :no-border-collapse="true"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    :current-page="currentPage"
                    :per-page="perPage">
                    <template v-slot:cell(created_on)="data">
                      {{ getFormattedDateTime(data.item.created_on) }}
                    </template>
                    <template v-slot:cell(source)="data">
                    <p v-html="data.item.source" style="word-wrap: break-word">
                    </p>
                  </template>
                    <template v-slot:cell(action)="data">
                      <b-button variant=" iq-bg-danger" @click="showUserRegLeadDeleteDialog(data.item)" size="sm"><i
                          class="ri-delete-bin-line m-0"></i></b-button>
                    </template>
                  </b-table>
                </b-col>
                <b-col md="12" v-else>
                  No data found
                </b-col>
              </b-row>
              <b-row>
                <b-col sm="7" md="8" class="my-1"></b-col>
                <b-col sm="5" md="4" class="my-1">
                  <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="sm"
                    class="my-0"></b-pagination>
                </b-col>
              </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>

      <b-modal v-model="showModelUserRegLeadDelete" size="sm" title="Delete" ok-title="Delete" cancel-title="Cancel">
        <p>{{ cvDelMessage }}</p>
        <template #modal-footer="">
          <!-- Emulate built in modal footer ok and cancel button actions -->
          <b-button size="sm" @click="showModelUserRegLeadDelete = false">
            {{ cvbtnModalCancel }}
          </b-button>
          <b-button size="sm" variant="primary" @click="userRegLeadDelete()">
            {{ cvbtnModalDelOk }}
          </b-button>
        </template>
      </b-modal>

      <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
        <div v-html="toastMsg"></div>
      </b-toast>
    </b-container>
</template>
<script>
import { UserRegLeads } from "../../../FackApi/api/UserRegLead"
import moment from "moment"
import ApiResponse from "../../../Utils/apiResponse.js"
import { socialvue } from "../../../config/pluginInit.js"
import userPermission from "../../../Utils/user_permission.js"

export default {
  name: "studentRegLeadList",
  components: {
  },
  data () {
    return {
      apiName: "student_reg_lead_list",
      cvCardTitle: "Contact Us - Student / Parents",
      cvbtnAddNew: "Add",
      cvSearchText: "Type to Search",
      cvbtnModalDelOk: "Delete",
      cvDelMessage: "Are you sure?",
      cvEditModalHeader: "Edit UserRegLead",
      cvAddModalHeader: "Add UserRegLead",
      cvbtnModalCancel: "Cancel",
      cvbtnModalExpand: "Expand",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "UserRegLead List Response",
      showModelUserRegLeadAdd: false,
      showModelUserRegLeadDelete: false,
      sortBy: "name",
      sortDesc: false,
      columns: [
        { label: "# ", key: "id", class: "text-left w-50px align-text-top text-nowrap ", sortable: true },
        { label: "User Type", key: "usr_type", class: "text-left w-100 align-text-top text-nowrap", sortable: true },
        { label: "First Name", key: "first_name", class: "text-left w-100 align-text-top text-nowrap", sortable: true },
        { label: "Last Name", key: "last_name", class: "text-left w-100 align-text-top text-nowrap", sortable: true },
        { label: "Gender  ", key: "gender", class: "text-left w-100 align-text-top text-nowrap", sortable: true },
        { label: "Student Email", key: "stu_email", class: "text-left w-100 align-text-top text-nowrap", sortable: true },
        { label: "Parent Email", key: "parent_email", class: "text-left w-100 align-text-top text-nowrap", sortable: true },
        { label: "Student Mobile", key: "stu_mobile", class: "text-left w-150 align-text-top text-nowrap", sortable: true },
        { label: "Parent Mobile ", key: "parent_mobile", class: "text-left w-100 align-text-top text-nowrap", sortable: true },
        { label: "Student City", key: "stu_city", class: "text-left w-100 align-text-top text-nowrap", sortable: true },
        { label: "Country", key: "stu_country", class: "text-left w-100 align-text-top text-nowrap", sortable: true },
        { label: "passing_year ", key: "passing_year", class: "text-left w-100 align-text-top text-nowrap", sortable: true },
        { label: "Grade", key: "grade", class: "text-left w-100 align-text-top text-nowrap", sortable: true },
        { label: "Board", key: "board", class: "text-left w-100 align-text-top text-nowrap", sortable: true },
        { label: "Preferred Country", key: "preferred_country", class: "text-left w-100 align-text-top text-nowrap", sortable: true },
        { label: "Org Name", key: "organisation_name", class: "text-left w-100 align-text-top text-nowrap", sortable: true },
        { label: "Product/Course", key: "course_name", class: "text-left w-100 align-text-top text-nowrap", sortable: true },
        { label: "Source", key: "source", class: "text-left w-100 align-text-top text-nowrap", sortable: true },
        { label: "Date", key: "created_on", class: "text-left w-100 align-text-top text-nowrap", sortable: true }
      ],
      userRegLeadObjList: null,
      filter: null,
      filterOn: [],
      studentfilter: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      delObj: null,
      updatedKey: 0,
      whereFilter: {
        dateRange: {
          startDate: new Date(new Date().setDate(new Date().getDate() - 7)), // in Last 7 Days
          endDate: new Date(new Date().setDate(new Date().getDate() + 1))
        },
        search_param: "",
        board: "null",
        grade: "null",
        product: "null"
      }
    }
  },
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    await this.studentFilterList("board")
    await this.studentFilterList("grade")
    await this.studentFilterList("product") // same as course name
    this.studentRegLeadList()
    socialvue.index()
  },
  methods: {
    /**
       * getFormattedDateTime
       */
    getFormattedDateTime (datetime) {
      try {
        return moment(datetime).format("DD-MM-YYYY hh:mm:ss")
      }
      catch (err) {
        console.log("Exception occurred at getFormattedDateTime() and Exception:", err.message)
      }
    },
    /**
     * Get daterange from LS. If found then use this as default else use the onse set in the data section of the object
     */
    async getDateFilter () {
      let storeDate = await this.dateFilter
      if (storeDate && storeDate.dateRange) {
        this.whereFilter.dateRange = { ...storeDate.dateRange }
      }
    },
    /**
     * Setting Date Range in the LS
     */
    async setDateFilter () {
      let endDate = this.whereFilter.dateRange.endDate
      this.whereFilter.dateRange.endDate = new Date(new Date(endDate).setDate(new Date(endDate).getDate() + 1))
    },
    /**
     * calling user_analytics and userlist function on date wise and storing value in store
     */
    async userListDateWise () {
      this.studentRegLeadList()
      await this.setDateFilter()
    },
    /**
     * backendCsvDownload
     */
    async backendCsvDownload () {
      this.whereFilter.downloadCsv = true
      this.studentRegLeadList(false, true)
    },
    /**
     * searchUserWithParams
     */
    async searchUserWithParams () {
      this.currentBatchNo = 1
      await this.studentRegLeadList()
    },
    /**
       * studentRegLeadList
       */
    async studentRegLeadList (loadViaLoadMore = false, downloadCsv = false) {
      try {
        let whereFilter = {}
        for (const property in this.whereFilter) {
          if (this.whereFilter[property] && this.whereFilter[property] != "null" && this.whereFilter[property] != " ") {
            whereFilter[property] = this.whereFilter[property]
            // console.log(`${property}: ${this.whereFilter[property]}`)
          }
        }
        whereFilter.downloadCsv = downloadCsv

        let studentRegLeadListResp = await UserRegLeads.studentRegLeadList(this, whereFilter)
        if (studentRegLeadListResp.resp_status) {
          if (this.whereFilter.downloadCsv) {
            this.whereFilter.downloadCsv = false
            window.open(studentRegLeadListResp.resp_csv_file_url)
            return
          }

          if (this.whereFilter.search_param.length >= 0 && !loadViaLoadMore) {
            this.userRegLeadObjList = [ ...studentRegLeadListResp.resp_data.data ]
          }
          else {
            this.userRegLeadObjList = studentRegLeadListResp.resp_data.data
          }
          this.totalRows = studentRegLeadListResp.resp_data.count
        }
        else {
          this.toastMsg = studentRegLeadListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.log("Exception occurred at studentRegLeadList() and Exception:", err.message)
      }
    },
    /**
       * showUserRegLeadDeleteDialog
       */
    showUserRegLeadDeleteDialog (item) {
      try {
        this.delObj = item
        this.showModelUserRegLeadDelete = true
      }
      catch (err) {
        console.error("Exception occurred at showUserRegLeadDeleteDialog() and Exception:", err.message)
      }
    },
    /**
       * userRegLeadDelete
       */
    async userRegLeadDelete () {
      try {
        let userRegLeadDelResp = await UserRegLeads.userRegLeadDelete(this, this.delObj.usrl_id)
        await ApiResponse.responseMessageDisplay(this, userRegLeadDelResp)

        if (userRegLeadDelResp && !userRegLeadDelResp) {
          this.showModelUserRegLeadDelete = false
          return false
        }

        let index = this.userRegLeadObjList.indexOf(this.delObj)
        this.userRegLeadObjList.splice(index, 1)
        this.totalRows = this.totalRows - 1
        this.showModelUserRegLeadDelete = false
      }
      catch (err) {
        console.error("Exception occurred at userRegLeadDelete() and Exception:", err.message)
      }
    },
    /**
       * closeUserRegLeadAddModal
       */
    closeUserRegLeadAddModal (userRegLeadAddData) {
      try {
        if (userRegLeadAddData) {
          if (this.userRegLeadObjList && this.userRegLeadObjList.length >= 1) {
            let usrlObjLength = this.userRegLeadObjList.length
            let lastId = this.userRegLeadObjList[usrlObjLength - 1]["id"]
            userRegLeadAddData.id = lastId + 1
          }
          else {
            this.userRegLeadObjList = []
            userRegLeadAddData.id = 11111
          }

          userRegLeadAddData.created_on = moment()
          this.userRegLeadObjList.unshift(userRegLeadAddData)
          this.totalRows = this.totalRows + 1
        }

        this.showModelUserRegLeadAdd = false
      }
      catch (err) {
        console.error("Exception occurred at closeuserRegLeadAddModal() and Exception:", err.message)
      }
    },

    /**
     * studentFilterList
    */
    async studentFilterList (filterName = null) {
      if (!filterName) {
        return
      }

      try {
        let studentFilterListResp = await UserRegLeads.studentFilterList(this, filterName)
        if (studentFilterListResp) {
          this.studentfilter[filterName] = studentFilterListResp.resp_data
          this.updatedKey += 1
        }
      }
      catch (err) {
        console.log("Exception occurred at studentFilterListResp() and Exception:", err.message)
      }
    },

    /**
     * setFilters
    */
    async setFilters () {
      await this.studentRegLeadList()
    }
  }
}
</script>
<style >
.dateRange {
  float: right;
}
</style>
